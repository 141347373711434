import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { useMessagesQuery } from "@app/api/messages/useMessagesQuery"
import { ArrowToRight } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { Message, MessagesParams } from "@app/model/messages"
import { createUserMessage, getInitials } from "@app/utils/helpers"
import { MessageType, WebSocketMessage } from "@app/webSocket/types"
import { useAuth0 } from "@auth0/auth0-react"
import { OutlinedInput } from "@mui/material"
import { Array, Order } from "effect"

type LocationState = {
  query?: string
}

const WEBSOCKET_URL = process.env.WEBSOCKET_URL

const ChatPage = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const { user, getAccessTokenSilently } = useAuth0()
  const { chatbotName, tenantName } = useChatbotContext()

  const [params, _setParams] = useState<MessagesParams>({
    role_id: chatbotName,
    limit: "50",
  })
  const [inputValue, setInputValue] = useState<string>("")
  const [newMessages, setNewMessages] = useState<WebSocketMessage[]>([])
  const [historyMessages, setHistoryMessages] = useState<Message[]>([])
  const [socketUrl, setSocketUrl] = useState<string | null>(null)
  const [protocols, setProtocols] = useState<string[]>([])
  const [isTyping, setIsTyping] = useState<boolean>(false)

  const queryValue = (location.state as LocationState)?.query

  const { isLoading: isMessagesLoading, data: messagesData } =
    useMessagesQuery(params)

  useEffect(() => {
    const webSocketInit = async (): Promise<void> => {
      const token = await getAccessTokenSilently()
      if (WEBSOCKET_URL && token) {
        const protocolsList = [`Bearer.${token}`]
        setSocketUrl(WEBSOCKET_URL)
        setProtocols(protocolsList)
      }
    }

    void webSocketInit()

    if (queryValue && chatbotName) {
      handleSendMessage(queryValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (messagesData) {
      const result = Array.sortBy(
        Order.mapInput(Order.number, (message: Message) => message?.createdAt),
      )(messagesData?.messages)

      setHistoryMessages(result)
    }
  }, [messagesData])

  const endOfMessagesRef = useRef<HTMLDivElement | null>(null)

  // Scroll to the latest message whenever messages change
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [newMessages, historyMessages])

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    protocols,
    reconnectAttempts: 2,
    reconnectInterval: 3000,
    retryOnError: true,
    share: true,
    shouldReconnect: () => true,
    onOpen: () => {},
    onClose: e => console.log("Closed", e),
    onMessage: message => console.log("Message", message),
    onError: error => console.error("WebSocket error:", error),
  })

  // Append incoming messages to the messages array
  useEffect(() => {
    if (lastMessage !== null) {
      const parsedMessage = JSON.parse(
        lastMessage.data as string,
      ) as WebSocketMessage
      setIsTyping(false)
      setNewMessages(prev => [...prev, parsedMessage] as WebSocketMessage[])
    }
  }, [lastMessage])

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState]

  console.info("connectionStatus", connectionStatus)

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (!inputValue.length) return

    if (event.key === "Enter") {
      event.preventDefault()

      handleSendMessage(inputValue)
      setInputValue("")
    }
  }

  const handleSendMessage = (text: string): void => {
    const message = createUserMessage(text, chatbotName, tenantName)
    sendMessage(JSON.stringify(message))
    setNewMessages(prev => [...prev, message] as WebSocketMessage[])
    setIsTyping(true)
  }

  const displayInitials = getInitials(user)

  const renderUserMessage = (text: string): JSX.Element => (
    <div className="flex ml-auto mb-5">
      <div className="w-fit bg-grey-50 p-4 rounded-tl-3xl rounded-bl-3xl rounded-br-3xl">
        {text}
      </div>
      <div className="flex items-center justify-center h-10 w-10 ml-3 rounded-full bg-grey-50 bdr-teal-800">
        <span className="text-l font-medium text-grey-900">
          {displayInitials}
        </span>
      </div>
    </div>
  )

  const renderAssistantMessage = (text: string): JSX.Element => (
    <div className="flex mb-5">
      <div className="flex items-center justify-center h-10 w-10 ml-3 rounded-full bg-grey-400 bdr-teal-800">
        <span className="text-l font-medium text-grey-900">AI</span>
      </div>
      <div className="w-fit px-4 ">{text}</div>
    </div>
  )

  return (
    <>
      <div className="flex overflow-scroll flex-col w-full h-full px-[20%]">
        <div className="flex flex-1 text-grey-900 flex-col p-6 w-full relative">
          <div className="flex flex-1 flex-col text-left">
            {isMessagesLoading && "loading..."}
            {Boolean(historyMessages) && (
              <>
                {historyMessages.map((msg, idx) => (
                  <div key={idx} className="flex flex-col text-left">
                    {msg.origin === "assistant"
                      ? renderAssistantMessage(msg?.content)
                      : renderUserMessage(msg?.content)}
                  </div>
                ))}
              </>
            )}
            {Boolean(newMessages) && (
              <>
                {newMessages.map((msg, idx) => (
                  <div key={idx} className="flex flex-col text-left">
                    {msg.type === MessageType.USER_MESSAGE &&
                      renderUserMessage(msg?.data?.text)}
                    {msg.type === MessageType.ASSISTANT_MESSAGE &&
                      renderAssistantMessage(msg?.data?.text)}
                  </div>
                ))}
              </>
            )}
            {isTyping && (
              <div className="typing-indicator">Assistant is typing...</div>
            )}
          </div>
          <div ref={endOfMessagesRef} />
        </div>
      </div>
      <div className="flex justify-center items-center mt-auto mb-4 px-[20%]">
        <OutlinedInput
          sx={{
            fontSize: "20px",
            borderRadius: "60px",
            height: "54px",
            width: "100%",
          }}
          placeholder={t("ask_me")}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={e => setInputValue(e.target.value)}
          endAdornment={
            <div className="flex justify-center items-center cursor-pointer bg-green-100 w-[30px] h-[30px] rounded-[50%]">
              <ArrowToRight />
            </div>
          }
        />
      </div>
    </>
  )
}
export default ChatPage
