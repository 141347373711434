import React from "react"
import { Link } from "react-router-dom"
import { EvolvLogo } from "@app/assets/Icons"

import ChatbotDropdown from "./ChatbotDropdown"
import ProfileHeader from "./ProfileHeader"

const Header = (): JSX.Element => {
  return (
    <div className="flex flex-row h-16 items-center justify-between px-4 pt-7 pb-3">
      <ChatbotDropdown />

      <div className="mr-6 hidden md:block">
        <Link to="/">
          <EvolvLogo className="" />
        </Link>
      </div>

      <div className="">
        <ProfileHeader />
      </div>
    </div>
  )
}

export default Header
