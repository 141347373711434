export type RequestMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE"
export const REST_URL = process.env.REST_URL
export const REST_URL_DASHBOARD = process.env.REST_URL_DASHBOARD
export const API_V1 = "/api/v1"

type RequestBodyData =
  | Record<string, unknown>
  | Record<string, unknown>[]
  | string
  | string[]

type FetchWithoutAuthParams = {
  path: string
  method: RequestMethod
  body?: RequestBodyData | FormData
  extraHeaders?: Record<string, unknown>
}

type AuthHeader = {
  Authorization: string
}

export const buildAuthHeader = (token: string): AuthHeader => {
  return {
    Authorization: `Bearer ${token}`,
  }
}

export const fetchWithoutAuth = async (
  params: FetchWithoutAuthParams,
): Promise<Response> => {
  const { path, method, body, extraHeaders } = params

  const endpoint = `${REST_URL}/${path}`
  const headers = {
    "Content-Type": "application/json",
    ...extraHeaders,
  }
  return fetch(endpoint, {
    headers,
    method,
    body: JSON.stringify(body),
  })
}
