import { Message, MessagesAPI, MessagesParams } from "@app/model/messages"
import * as S from "@effect/schema/Schema"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey" // Ensure this exports a type, not just a constant
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = MessagesAPI
type QueryKeyType = ReturnType<typeof QueryKey.messagesList>

const MessagesSchema: S.Schema<Message> = S.Struct({
  id: S.String,
  userId: S.String,
  content: S.String,
  origin: S.String,
  createdAt: S.Number,
  parentId: S.NullOr(S.String),
  citations: S.Unknown, // not finalized on BE side
})

const MessagesAPISchema: S.Schema<MessagesAPI> = S.Struct({
  messages: S.Array(MessagesSchema),
  count: S.Number,
  hasMore: S.Boolean,
})

export const getMessagesList: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, params] = queryKey
  const { role_id, limit } = params

  const paramsString = new URLSearchParams({
    role_id,
    limit,
  }).toString()

  const messagesPath = `${REST_URL}${API_V1}/messages/my?${paramsString}`

  const token = meta?.token as string

  const response = await fetch(messagesPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw Error("Get messages fails")
  }

  const results = (await response.json()) as unknown

  return pipe(results, S.decodeUnknownSync(MessagesAPISchema))
}

export const useMessagesQuery = (
  params: MessagesParams,
  options?: Omit<
    UseQueryOptions<QueryResultData, Error, QueryResultData, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<QueryResultData, Error> => {
  const queryKey = QueryKey.messagesList(params)

  return useAuthenticatedQuery<QueryResultData, Error, QueryKeyType>(
    queryKey,
    getMessagesList,
    options,
  )
}
