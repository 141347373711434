import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ArrowToRight } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { OutlinedInput } from "@mui/material"
import { v4 as uuidv4 } from "uuid"

const suggestedQuestions = [
  "What is the hospital's fire evacuation plan?",
  "How to use firefighting equipment?",
  "How to evacuate patients safely during a bomb alert?",
]

const MainPage = (): JSX.Element => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string>("")
  const { chatbotName } = useChatbotContext()

  const navigate = useNavigate()

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (!inputValue.length) return

    if (event.key === "Enter") {
      event.preventDefault()
      const newChatId = uuidv4()

      navigate(`/chat/${newChatId}`, { state: { query: inputValue } })
    }
  }

  const handleOnQuestionClick = (question: string): void => {
    const newChatId = uuidv4()

    navigate(`/chat/${newChatId}`, { state: { query: question } })
  }

  return (
    <div className="flex w-full h-full justify-center items-center ">
      <div className="flex flex-col items-center w-full max-w-[620px]">
        <h1 className="text-6xl">{t("common.title")}</h1>
        <OutlinedInput
          sx={{
            fontSize: "20px",
            borderRadius: "60px",
            height: "54px",
            width: "100%",
          }}
          onKeyDown={handleKeyDown}
          onChange={e => setInputValue(e.target.value)}
          placeholder={t("ask_me")}
          endAdornment={
            <div className="flex justify-center items-center cursor-pointer bg-green-100 w-[30px] h-[30px] rounded-[50%]">
              <ArrowToRight />
            </div>
          }
        />
        {chatbotName === "evolv_hospital" && (
          <ul className="list-none">
            {suggestedQuestions.map((question, index) => (
              <li key={index} className="flex justify-center mb-3">
                <span
                  onClick={() => handleOnQuestionClick(question)}
                  className="bg-grey-50 rounded-[20px] py-2 px-5 cursor-pointer"
                >
                  {question}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
export default MainPage
