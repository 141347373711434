import { MessagesParams } from "@app/model/messages"

export const messagesList = (
  params: MessagesParams,
): ["messages", MessagesParams] => {
  return ["messages", params]
}

export const validateToken = (
  token: string,
): ["validation", { token: string }] => {
  return ["validation", { token }]
}

export const userDetails = (
  role_id: string,
): ["userDetails", { role_id: string }] => {
  return ["userDetails", { role_id }]
}

export const QueryKey = {
  userDetails,
  messagesList,
  validateToken,
}
