import { UserFlowType } from "@app/model/user"
import * as S from "@effect/schema/Schema"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL_DASHBOARD } from "../api"

type QueryResultData = UserFlowType

const TokenValidationSchema: S.Schema<UserFlowType> = S.Struct({
  flowType: S.String,
})

const checkFlowType = async (
  invitationToken: string,
  email: string,
): Promise<QueryResultData> => {
  const path = `${REST_URL_DASHBOARD}${API_V1}/account/flow-type?invite_id=${email}`
  const response = await fetch(path, {
    method: "GET",
    headers: {
      ...buildAuthHeader(invitationToken),
    },
  })
  if (!response.ok) {
    throw new Error("Failed to check flow type")
  }

  const results = (await response.json()) as UserFlowType
  return pipe(results, S.decodeUnknownSync(TokenValidationSchema))
}

export const useFlowTypeQuery = (
  invitationToken: string,
  options?: UseMutationOptions<QueryResultData, Error, string>,
): UseMutationResult<QueryResultData, Error, string> => {
  return useMutation<QueryResultData, Error, string>({
    mutationFn: async (email: string) => checkFlowType(invitationToken, email),
    ...options,
  })
}
