import { MessageType, WebSocketMessage } from "@app/webSocket/types"
import { User } from "@auth0/auth0-react"
import { Option, pipe, String } from "effect"
import { v4 as uuidv4 } from "uuid"

export const createUserMessage = (
  text: string,
  chatbotId: string = "evolv_hospital",
  tenantName: string,
): WebSocketMessage => {
  const newMessageId = uuidv4()
  return {
    type: MessageType.USER_MESSAGE,
    data: {
      message_id: newMessageId,
      text,
      chatbot_external_id: chatbotId,
      tenant_name: tenantName,
    },
  }
}

export const getInitials = (user?: User): string => {
  const firstName = user?.given_name || "Iron"
  const lastName = user?.family_name || "Man"

  const firstInitial = pipe(
    firstName,
    String.at(0),
    Option.map(String.toUpperCase),
    Option.getOrElse(() => "?"),
  )
  const lastInitial = pipe(
    lastName,
    String.at(0),
    Option.map(String.toUpperCase),
    Option.getOrElse(() => "?"),
  )

  return `${firstInitial}${lastInitial}`
}

export const getFullName = (user?: User): string => {
  const firstName = user?.given_name || "Iron"
  const lastName = user?.family_name || "Man"

  return `${firstName} ${lastName}`
}
