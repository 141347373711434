import React from "react"
import {
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
import { queryClient } from "@app/api/queryClient"
import {
  InfoIcon,
  SettingsIcon,
  ShieldIcon,
  SidebarIcon,
} from "@app/assets/Icons"
import { ChatbotProvider } from "@app/context/ChatbotContext"
import PageNotFound from "@app/uiKit/PageNotFound"
import PageWrapper from "@app/uiKit/PageWrapper"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { CircularProgress } from "@mui/material"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { v4 as uuidv4 } from "uuid"

import AccessPage from "./AccessPage/AccessPage"
import ChatPage from "./ChatPage"
import Header from "./Header"
import LoginScreen from "./LoginScreen"
import MainPage from "./MainPage"

const domain = process.env.AUTH0_DOMAIN as string
const clientId = process.env.AUTH0_CLIENT_ID as string

const App = (): JSX.Element => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${domain}/api/v2/`,
        scope: "openid profile email offline_access",
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <QueryClientProvider client={queryClient}>
        <Sentry.ErrorBoundary>
          <ChatbotProvider>
            <HashRouter>
              <AppView />
            </HashRouter>
          </ChatbotProvider>
        </Sentry.ErrorBoundary>
      </QueryClientProvider>
    </Auth0Provider>
  )
}

const AppView = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return (
      <div className={"flex h-full items-center justify-center"}>
        <CircularProgress
          sx={() => ({
            color: "#3EAA89",
          })}
        />
      </div>
    )
  }
  return (
    <Routes>
      <Route index element={<Navigate to="/chat" replace />} />
      <Route path="/access" element={<AccessPage />} />
      <Route path="login" element={<NonAuthenticatedApp />} />

      <Route
        path="chat"
        element={
          isAuthenticated ? <AuthenticatedApp /> : <Navigate to="/login" />
        }
      >
        <Route index element={<MainPage />} />
        <Route path="/chat/:chatId" element={<ChatPage />} />
      </Route>

      <Route
        path="*"
        element={
          <PageWrapper>
            <PageNotFound />
          </PageWrapper>
        }
      />
    </Routes>
  )
}

const NonAuthenticatedApp = (): JSX.Element => {
  return (
    <div className="flex flex-col h-full relative">
      <LoginScreen />
    </div>
  )
}

const AuthenticatedApp = (): JSX.Element => {
  return <AuthenticatedAppView />
}

const AuthenticatedAppView = (): JSX.Element => {
  const navigate = useNavigate()
  const handleNavigate = (): void => {
    const newChatId = uuidv4()

    navigate(`/chat/${newChatId}`)
  }
  return (
    <div className="flex w-full h-full">
      <div className="h-screen w-[60px] flex p-2">
        <div className="flex flex-col rounded-[10px] bg-grey-50 justify-center px-2 py-2">
          <div className="flex flex-1" onClick={handleNavigate}>
            <SidebarIcon />
          </div>
          <div className="flex flex-col items-center justify-center mt-auto">
            <ShieldIcon className="mb-4" />
            <InfoIcon className="mb-4" />
            <SettingsIcon />
          </div>
        </div>
      </div>
      <div className="h-screen flex flex-col justify-start w-full">
        <Header />
        <Outlet />
      </div>
    </div>
  )
}
export default App
