{
  "ask_me": "Ask me anything",
  "common": {
    "bot_answer": "Bot’s answer",
    "chatbot": "Chatbot",
    "cancel": "Cancel",
    "title": "EvolvGPT",
    "delete": "Delete",
    "no_data": "No data",
    "not_available": "N/A",
    "page_not_found": "Page not found.",
    "something_went_wrong": "Something went wrong",
    "user_question": "User’s question:"
  },
  "form_validation": {
    "email_invalid": "Enter a valid email",
    "field_invalid": "Field invalid",
    "field_required": "Field required",
    "number_invalid": "Must be a number",
    "confirm_password": "Passwords don't match",
    "password": {
      "special": "Password must have at least 1 special character",
      "uppercase": "Password must have at least 1 upper case character",
      "short": "Password must be at least 8 characters long"
    }
  },
  "conversation": {
    "all_questions": "All questions ({{ questionsCount }})",
    "table": {
      "header": {
        "created_at": "Created At",
        "question": "Question",
        "rated": "Rated",
        "user_name": "User Name"
      }
    },
    "upload_new_file": "Upload a new file to load questions."
  },
  "date_filter": {
    "last_24_hours": "Last 24 Hours",
    "last_30_days": "Last 30 Days",
    "last_7_days": "Last 7 Days",
    "this_year": "Year"
  },
  "header": {
    "profile": {
      "bot": "Bot",
      "dropdown": {
        "log_in": "Log In",
        "log_out": "Log Out"
      },
      "hi": "Hi",
      "isop": "ISOP"
    }
  },
  "modal": {
    "delete": "Are you sure you want to delete, {{ file }} file from the library?"
  },
  "uploadFile": {
    "button": "Upload File From PC",
    "no_files": "No Files have been uploaded.",
    "total_files_uploaded": "Total Files Uploaded",
    "uploaded": "File successfully uploaded.",
    "uploading": "New File Is being uploaded..."
  },
  "login_screen": {
    "email_address": "Email Address",
    "next": "Next",
    "create_account": "Create Account",
    "sign_in_eva_dashboard": "Sign in to EvolvGPT",
    "enter_password": "Enter your password",
    "enter_your_data": "Create an Account",
    "firstName": "First Name",
    "lastName": "Last Name",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "user_role": "User Role",
    "roles": {
      "user": "User",
      "admin": "Admin"
    }
  }
}
