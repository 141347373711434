import React, { KeyboardEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useFlowTypeQuery } from "@app/api/account/useFlowTypeQuery"
import { useAdminCreationMutation } from "@app/api/invitation/useAdminAcceptQuery"
import { useUserCreationMutation } from "@app/api/invitation/useUserAcceptQuery"
import {
  Email,
  EvolvLogo,
  EyeClosedIcon,
  EyeIcon,
  PasswordIcon,
  UserIconLight,
  UsersIcon,
} from "@app/assets/Icons"
import { localizeYupSchema } from "@app/i18n/yup"
import { FlowType } from "@app/model/account"
import { YupUtils } from "@app/services"
import { CustomCheckbox } from "@app/uiKit/Checkbox"
import FormSubmitButton from "@app/uiKit/FormSubmitButton"
import { useAuth0 } from "@auth0/auth0-react"
import { InputAdornment, MenuItem, TextField } from "@mui/material"
import cn from "classnames"
import { FormikErrors, FormikHandlers, useFormik } from "formik"
import * as yup from "yup"

const domain = process.env.AUTH0_DOMAIN
const audience = `https://${domain}/api/v2/`

type FormValues = {
  userRole?: string
  firstName?: string
  lastName?: string
  email?: string
  password?: string
  confirmPassword?: string
}

type LocationState = {
  from?: string
  invitationToken: string
  email: string
}

type EmailInputProps = {
  values: FormValues
  errors: FormikErrors<FormValues>
  dirty: boolean
  isSubmitting: boolean
  flowType?: string
  userEmailFromToken?: string
  handleChange: FormikHandlers["handleChange"]
  handleSubmit: FormikHandlers["handleSubmit"]
}

const LoginScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()
  const location = useLocation()

  const fromRoute = (location.state as LocationState)?.from
  const invitationToken = (location.state as LocationState)?.invitationToken
  const userEmailFromToken = (location.state as LocationState)?.email

  const isInvitationFlow = fromRoute === "/access"

  const [selectedEmail, setSelectedEmail] = useState<string>("")
  const [flowType, setFlowType] = useState<FlowType>(FlowType.LOGIN)

  const { data, mutateAsync: checkFlowType } = useFlowTypeQuery(invitationToken)

  const { data: userAdminData, mutateAsync: createAdminUser } =
    useAdminCreationMutation(invitationToken)

  const { data: userUserData, mutateAsync: createUser } =
    useUserCreationMutation(invitationToken)

  const userData = userAdminData || userUserData

  const handleLogin = async ({
    firstName,
    lastName,
    email,
    password,
  }: FormValues): Promise<void> => {
    setSelectedEmail(email || "")

    if (
      flowType !== FlowType.SIGNUP &&
      isInvitationFlow &&
      email &&
      !password
    ) {
      await checkFlowType(email)
      return
    }

    if (flowType === FlowType.LOGIN && email) {
      await handleOktaLogin(email)
    }

    if (flowType === FlowType.SIGNUP && password && firstName && lastName) {
      const params = {
        email: selectedEmail,
        password,
        firstName,
        lastName,
      }
      if (userEmailFromToken) {
        await createUser(params)
      } else {
        await createAdminUser(params)
      }
    }
  }

  useEffect(() => {
    if (data?.flowType === FlowType.SIGNUP) {
      setFlowType(data?.flowType)
    } else if (data?.flowType === FlowType.LOGIN) {
      void handleOktaLogin(selectedEmail)
    }

    if (userData) {
      setFlowType(FlowType.LOGIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.flowType, userData])

  const handleOktaLogin = async (email: string): Promise<void> => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          scope: "openid profile email offline_access",
          audience,
          prompt: "login",
          login_hint: email,
          // connection does not needed for a new created user,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const validationSchema = localizeYupSchema<FormValues>(
    t,
    yup.object({
      email: yup
        .string()
        .email()
        .when([], {
          is: () => flowType === FlowType.LOGIN,
          then: schema => schema.required(),
          otherwise: schema => schema.notRequired(),
        }),
      firstName: yup.string().when([], {
        is: () => flowType === FlowType.SIGNUP,
        then: schema => schema.required(t("form_validation.field_required")),
        otherwise: schema => schema.notRequired(),
      }),
      lastName: yup.string().when([], {
        is: () => flowType === FlowType.SIGNUP,
        then: schema => schema.required(t("form_validation.field_required")),
        otherwise: schema => schema.notRequired(),
      }),
      password: yup.string().when([], {
        is: () => flowType === FlowType.SIGNUP,
        then: schema =>
          schema
            .required()
            .ensure()
            .test("password", "password", YupUtils.testPassword),
        otherwise: schema => schema.notRequired(),
      }),
      confirmPassword: yup.string().when([], {
        is: () => flowType === FlowType.SIGNUP,
        then: schema =>
          schema
            .ensure()
            .oneOf(
              [yup.ref("password")],
              t("form_validation.confirm_password"),
            ),
        otherwise: schema => schema.notRequired(),
      }),
    }),
  )

  const formikProps = useFormik({
    validationSchema,
    initialValues: {
      userRole: "user",
      firstName: "",
      lastName: "",
      email: userEmailFromToken || "",
      password: "",
    },
    onSubmit: handleLogin,
    validateOnChange: false,
  })

  const containerClassName = cn(
    "xs:w-[90%] sm:w-[65%] lg:w-[30%] rounded-[32px] bg-grey-50 p-6 sm:py-12 sm:px-16",
  )

  return (
    <>
      <LoginScreenHeader />
      {userData && <UserCreatedScreen />}
      <div className="flex w-full h-full items-center justify-center">
        <div className={containerClassName}>
          <div className="text-xl sm:text-2xl font-medium pb-10">
            {flowType === FlowType.LOGIN
              ? t("login_screen.sign_in_eva_dashboard")
              : t("login_screen.enter_your_data")}
          </div>
          <InputField
            {...formikProps}
            flowType={flowType}
            userEmailFromToken={userEmailFromToken}
          />
        </div>
      </div>
    </>
  )
}

const LoginScreenHeader = (): JSX.Element => {
  return (
    <div
      data-testid="login-logo-container"
      className="flex h-16 items-center justify-center pt-7 w-full"
    >
      <EvolvLogo />
    </div>
  )
}

const UserCreatedScreen = (): JSX.Element => {
  return (
    <div className="mt-10 text-center">
      {"The user was created successfully! You can log in now."}
    </div>
  )
}

const InputField = ({
  values,
  dirty,
  errors,
  isSubmitting,
  flowType,
  userEmailFromToken,
  handleSubmit,
  handleChange,
}: EmailInputProps): JSX.Element => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isPolicyAndTermsChecked, setIsPolicyAndTermsChecked] = useState(false)

  const togglePasswordVisibility = (): void => {
    setShowPassword(prev => !prev)
  }
  const toggleConfirmPasswordVisibility = (): void => {
    setShowConfirmPassword(prev => !prev)
  }

  const firstNameIsNotEmpty = values.firstName
  const lastNameIsNotEmpty = values.lastName
  const emailIsNotEmpty = values.email
  const passwordIsNotEmpty = values.password
  const confirmPasswordIsNotEmpty = values.confirmPassword

  const errorEmail = errors?.email
  const errorPassword = errors?.password
  const errorConfirmPassword = errors?.confirmPassword
  const errorFirstName = errors?.firstName
  const errorLastName = errors?.lastName

  const translatedError = errors?.password?.split(",").map(error => {
    return t(`form_validation.password.${error}`)
  })

  const policyAndTermsDisabled =
    flowType === FlowType.SIGNUP && !isPolicyAndTermsChecked

  const isSubmitDisabled =
    policyAndTermsDisabled ||
    (userEmailFromToken ? false : !dirty || isSubmitting)

  const handleLogin = (): void => {
    handleSubmit()
  }

  const inputClassName =
    "block bg-grey-50 border-none h-12 focus:outline-none sm:w-full ml-11 text-grey-900 custom-input autofill:shadow-[inset_0_0_0px_1000px_#DFEAE9]"

  const inputContainerClassName =
    "relative w-full border-b border-solid border-x-0 border-t-0 mb-3"

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  const handleOnChangePolicyAndTermsCheckboxValue = (): void => {
    setIsPolicyAndTermsChecked(!isPolicyAndTermsChecked)
  }

  return (
    <>
      <div className="mb-7">
        {flowType === FlowType.SIGNUP ? (
          <>
            <div className="h-3 -mt-1">
              {emailIsNotEmpty && (
                <label
                  htmlFor="emailAddress"
                  className="text-xs block text-grey-100"
                >
                  {t("login_screen.email_address")}
                </label>
              )}
            </div>
            <div
              className={cn(inputContainerClassName, {
                "border-red-500": errorEmail,
                "border-grey-100": !errorEmail,
              })}
            >
              <div className="absolute mt-3 ml-3">
                <Email
                  className={cn({
                    "stroke-red-500": errorEmail,
                    "stroke-grey-100": !errorEmail,
                  })}
                />
              </div>
              <input
                id="emailAddress"
                type="email"
                value={values.email}
                autoComplete="off"
                disabled={true}
                className={cn(
                  "block bg-grey-50 border-none h-12 focus:outline-none sm:w-full ml-11 text-grey-100 custom-input ",
                  {
                    "text-red-500": errorEmail,
                  },
                )}
                placeholder={t("login_screen.email_address")}
                onChange={handleChange("email")}
                onKeyDown={handleKeyDown}
              />
            </div>
            <TextField
              className="w-full"
              variant="standard"
              sx={{
                "& .MuiInputBase-root.MuiInput-root": {
                  height: "48px",
                  fontSize: "14px",
                  marginBottom: "0.75rem",
                },
                "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::before":
                  {
                    borderBottom: "1px solid #91A3A3",
                  },
                "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::after":
                  {
                    borderBottom: "1px solid #91A3A3",
                    transform: "none",
                  },
                "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input:focus":
                  {
                    backgroundColor: "#ffffff",
                  },
                "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                  color: "initial",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="ml-3">
                    <UsersIcon />
                  </InputAdornment>
                ),
              }}
              name="userRole"
              select
              value={values.userRole}
              onChange={handleChange}
              disabled={true}
              label={t("login_screen.user_role")}
            >
              <MenuItem value="user">{t("login_screen.roles.user")}</MenuItem>
              <MenuItem value="admin">{t("login_screen.roles.admin")}</MenuItem>
            </TextField>
            <div className="h-3 -mt-1">
              {firstNameIsNotEmpty && (
                <label htmlFor="firstName" className="text-xs block">
                  {t("login_screen.firstName")}
                </label>
              )}
            </div>
            <div
              className={cn(inputContainerClassName, {
                "border-red-500": errorFirstName,
                "border-stone-500": !errorFirstName,
              })}
            >
              <div className="absolute mt-3 ml-3">
                <UserIconLight
                  className={cn({
                    "stroke-red-500": errorFirstName,
                    "stroke-grey-900": !errorFirstName,
                  })}
                />
              </div>
              <input
                id="firstName"
                type="text"
                value={values.firstName}
                autoComplete="off"
                className={cn(inputClassName)}
                placeholder={t("login_screen.firstName")}
                onChange={handleChange("firstName")}
                onKeyDown={handleKeyDown}
              />
            </div>
            {errorFirstName && (
              <div
                className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}
              >
                {errorFirstName}
              </div>
            )}
            <div className="h-3 -mt-1">
              {lastNameIsNotEmpty && (
                <label htmlFor="lastName" className="text-xs block">
                  {t("login_screen.lastName")}
                </label>
              )}
            </div>
            <div
              className={cn(inputContainerClassName, {
                "border-red-500": errorLastName,
                "border-stone-500": !errorLastName,
              })}
            >
              <div className="absolute mt-3 ml-3">
                <UserIconLight
                  className={cn({
                    "stroke-red-500": errorLastName,
                    "stroke-grey-900": !errorLastName,
                  })}
                />
              </div>
              <input
                id="lastName"
                type="text"
                value={values.lastName}
                autoComplete="off"
                className={cn(inputClassName)}
                placeholder={t("login_screen.lastName")}
                onChange={handleChange("lastName")}
                onKeyDown={handleKeyDown}
              />
            </div>
            {errorLastName && (
              <div
                className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}
              >
                {errorLastName}
              </div>
            )}
            <div className="h-3 -mt-1">
              {passwordIsNotEmpty && (
                <label htmlFor="password" className="text-xs block">
                  {t("login_screen.password")}
                </label>
              )}
            </div>
            <div
              className={cn(inputContainerClassName, {
                "border-red-500": errorPassword,
                "border-stone-500": !errorPassword,
              })}
            >
              <div className="absolute mt-3 ml-3">
                <PasswordIcon
                  className={cn({
                    "stroke-red-500": errorPassword,
                    "stroke-grey-900": !errorPassword,
                  })}
                />
              </div>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                autoComplete="new-password"
                className={cn(inputClassName, {
                  "text-red-500": errorPassword,
                })}
                placeholder={t("login_screen.password")}
                onChange={handleChange("password")}
                onKeyDown={handleKeyDown}
              />
              <button
                type="button"
                className="absolute right-3 top-2 p-0 m-0 bg-transparent border-none focus:outline-none cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <EyeIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <EyeClosedIcon className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            {errorPassword && (
              <div
                className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}
              >
                {translatedError?.map((error, i) => {
                  return <div key={i}>{error}</div>
                })}
              </div>
            )}
            <div className="h-3 -mt-1">
              {confirmPasswordIsNotEmpty && (
                <label htmlFor="confirmPassword" className="text-xs block">
                  {t("login_screen.confirm_password")}
                </label>
              )}
            </div>
            <div
              className={cn(inputContainerClassName, {
                "border-red-500": errorConfirmPassword,
                "border-stone-500": !errorConfirmPassword,
              })}
            >
              <div className="absolute mt-3 ml-3">
                <PasswordIcon
                  className={cn({
                    "stroke-red-500": errorConfirmPassword,
                    "stroke-grey-900": !errorConfirmPassword,
                  })}
                />
              </div>
              <input
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                autoComplete="new-password"
                className={cn(inputClassName)}
                placeholder={t("login_screen.confirm_password")}
                onChange={handleChange("confirmPassword")}
                onKeyDown={handleKeyDown}
              />
              <button
                type="button"
                className="absolute right-3 top-2 p-0 m-0 bg-transparent border-none focus:outline-none cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <EyeIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <EyeClosedIcon className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            {errorConfirmPassword && (
              <div
                className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}
              >
                {errorConfirmPassword}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="h-3 -mt-1">
              {emailIsNotEmpty && (
                <label htmlFor="emailAddress" className="text-xs block">
                  {t("login_screen.email_address")}
                </label>
              )}
            </div>
            <div
              className={cn(inputContainerClassName, {
                "border-red-500": errorEmail,
                "border-stone-500": !errorEmail,
              })}
            >
              <div className="absolute mt-3 ml-3">
                <Email
                  className={cn({
                    "stroke-red-500": errorEmail,
                    "stroke-grey-900": !errorEmail,
                  })}
                />
              </div>
              <input
                id="emailAddress"
                type="email"
                value={values.email}
                autoComplete="off"
                disabled={Boolean(userEmailFromToken)}
                className={cn(inputClassName, {
                  "text-red-500": errorEmail,
                })}
                placeholder={t("login_screen.email_address")}
                onChange={handleChange("email")}
                onKeyDown={handleKeyDown}
              />
            </div>
            {errorEmail && (
              <div
                className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}
              >
                {errorEmail}
              </div>
            )}
          </>
        )}
      </div>

      {flowType === FlowType.SIGNUP && (
        <label className="flex items-center mb-7">
          <CustomCheckbox
            checked={isPolicyAndTermsChecked}
            onChange={handleOnChangePolicyAndTermsCheckboxValue}
          />
          <span className="text-xs">
            I have read and I accept the{" "}
            <a
              href="https://static.chatbot.evolv.com/privacy-policy.html"
              target="_blank"
              className="text-teal-800"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            &{" "}
            <a
              href="https://static.chatbot.evolv.com/terms-of-service.html"
              target="_blank"
              className="text-teal-800"
              rel="noreferrer"
            >
              Terms and Conditions.
            </a>
          </span>
        </label>
      )}

      <FormSubmitButton
        testID="login-next-button"
        onPress={handleLogin}
        isLoading={isSubmitting}
        isDisabled={isSubmitDisabled}
        text={t(
          `${flowType === FlowType.SIGNUP ? "login_screen.create_account" : "login_screen.next"}`,
        )}
      />
    </>
  )
}

export default LoginScreen
