import { AdminAcceptParams, UserDetails } from "@app/model/user"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL_DASHBOARD } from "../api"

type QueryResultData = UserDetails

const acceptAdminUser = async (
  invitationToken: string,
  credentials: AdminAcceptParams,
): Promise<QueryResultData> => {
  const path = `${REST_URL_DASHBOARD}${API_V1}/invitation/admin/accept`
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify({ ...credentials }),
    headers: {
      "Content-Type": "application/json",
      ...buildAuthHeader(invitationToken),
    },
  })

  if (!response.ok) {
    throw new Error("Failed to create admin user ")
  }

  const results = (await response.json()) as UserDetails
  return results
}

export const useAdminCreationMutation = (
  invitationToken: string,
  options?: UseMutationOptions<QueryResultData, Error, AdminAcceptParams>,
): UseMutationResult<QueryResultData, Error, AdminAcceptParams> => {
  return useMutation<QueryResultData, Error, AdminAcceptParams>({
    mutationFn: async (credentials: AdminAcceptParams) =>
      acceptAdminUser(invitationToken, credentials),
    ...options,
  })
}
