type IconSvgProps = {
  className?: string
}

export const EvolvLogo = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="124"
    height="32"
    viewBox="0 0 124 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M92.156 0H84.7656V31.5222H92.156V0Z" fill="#24272F" />
    <path
      d="M66.8712 25.3376C63.9153 25.3376 61.5044 23.4116 61.5044 19.901C61.5044 16.3968 63.9139 14.4645 66.8712 14.4645C69.827 14.4645 72.2759 16.3904 72.2759 19.901C72.2816 23.4116 69.827 25.3376 66.8712 25.3376ZM66.8712 7.80371C59.7188 7.80371 54.231 12.4956 54.231 19.901C54.231 27.2636 59.7132 31.9983 66.8712 31.9983C74.101 31.9983 79.5508 27.2636 79.5508 19.901C79.5508 12.4956 74.1066 7.80371 66.8712 7.80371Z"
      fill="#24272F"
    />
    <path
      d="M25.3029 19.8142C25.3029 12.4659 19.8982 7.81055 12.7275 7.81055C5.62442 7.8042 0.181641 12.4596 0.181641 19.8078C0.181641 20.3016 0.211213 20.7875 0.257685 21.2559H0.252052L0.329504 21.8862C1.21668 27.9674 6.13279 32.0004 12.9768 32.0004C19.0363 32.0004 24.1595 28.801 24.8143 23.3787H17.6943C16.7818 24.9491 14.9764 25.6016 12.9387 25.6016C10.1744 25.6016 8.19584 24.2838 7.49595 21.9164L7.43399 21.3448H23.561V21.3369H25.2184V21.3448C25.2762 20.8431 25.3029 20.3366 25.3029 19.8142ZM7.43258 19.106C7.73254 16.0702 9.99273 14.4141 12.7261 14.4141C15.4595 14.4141 17.752 16.0702 18.0576 19.106H7.43258Z"
      fill="#24272F"
    />
    <path
      d="M39.9149 22.7548L34.0792 8.33301H25.8354L35.7888 31.4781H43.565L53.4803 8.33301H45.7041L39.9149 22.7548Z"
      fill="#24272F"
    />
    <path
      d="M115.769 8.3252L114.589 11.08H122.365L123.545 8.3252H115.769Z"
      fill="#24272F"
    />
    <path
      d="M111.045 19.3531H118.82L121.184 13.834H113.409L111.045 19.3531Z"
      fill="#24272F"
    />
    <path
      d="M107.055 15.5401L104.142 8.3252H95.8965L105.851 31.4782H113.494L113.491 31.4703H113.626L117.549 22.3151L109.784 22.2929L107.055 15.5401Z"
      fill="#24272F"
    />
  </svg>
)

export const SidebarIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_133_3865)">
      <path
        d="M7.5 10.5C7.5 9.57174 7.86875 8.6815 8.52513 8.02513C9.1815 7.36875 10.0717 7 11 7H32C32.9283 7 33.8185 7.36875 34.4749 8.02513C35.1312 8.6815 35.5 9.57174 35.5 10.5V31.5C35.5 32.4283 35.1312 33.3185 34.4749 33.9749C33.8185 34.6312 32.9283 35 32 35H11C10.0717 35 9.1815 34.6312 8.52513 33.9749C7.86875 33.3185 7.5 32.4283 7.5 31.5V10.5Z"
        stroke="#1B1F2A"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 7V35"
        stroke="#1B1F2A"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_133_3865">
        <rect width="42" height="42" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export const UpArrow = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    fill="none"
  >
    <g clipPath="url(#clip0_880_438)">
      <path
        d="M18 14L12 8L6 14L18 14Z"
        stroke="#24272F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_880_438">
        <rect
          width="24"
          height="24"
          fill="#EAFFFE"
          transform="matrix(-1 0 0 -1 24 24)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const DownArrow = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    fill="none"
  >
    <g clipPath="url(#clip0_614_14211)">
      <path
        d="M6 10L12 16L18 10H6Z"
        stroke="#24272F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_614_14211">
        <rect width="24" height="24" fill="#EAFFFE" />
      </clipPath>
    </defs>
  </svg>
)

export const LogIn = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
    />
  </svg>
)

export const LogOut = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={className}
    fill="none"
  >
    <g clipPath="url(#clip0_884_3078)">
      <path
        d="M18.6667 10.6668V8.00016C18.6667 7.29292 18.3857 6.61464 17.8856 6.11454C17.3855 5.61445 16.7072 5.3335 16 5.3335H6.66667C5.95942 5.3335 5.28115 5.61445 4.78105 6.11454C4.28095 6.61464 4 7.29292 4 8.00016V24.0002C4 24.7074 4.28095 25.3857 4.78105 25.8858C5.28115 26.3859 5.95942 26.6668 6.66667 26.6668H16C16.7072 26.6668 17.3855 26.3859 17.8856 25.8858C18.3857 25.3857 18.6667 24.7074 18.6667 24.0002V21.3335"
        stroke="#24272F"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16H28L24 12"
        stroke="#24272F"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 20L28 16"
        stroke="#24272F"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_884_3078">
        <rect width="32" height="32" fill="#EAFFFE" />
      </clipPath>
    </defs>
  </svg>
)

export const ArrowToRight = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.25 6H13.75"
      stroke="black"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 11L13.75 6"
      stroke="black"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 1L13.75 6"
      stroke="black"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ShieldIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="19"
    height="24"
    viewBox="0 0 19 24"
    fill="none"
    className={className}
  >
    <path
      d="M9.49984 12H16.7591C16.2095 16.4836 13.3576 20.4873 9.49984 21.7309V12H2.24058V5.78182L9.49984 2.38909M9.49984 0L0.166504 4.36364V10.9091C0.166504 16.9636 4.14873 22.6145 9.49984 24C14.8509 22.6145 18.8332 16.9636 18.8332 10.9091V4.36364L9.49984 0Z"
      fill="#1B1F2A"
    />
  </svg>
)

export const InfoIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="9"
    height="18"
    viewBox="0 0 9 18"
    fill="none"
    className={className}
  >
    <path
      d="M6.63277 0C6.13755 0 5.66262 0.177741 5.31244 0.494122C4.96227 0.810503 4.76554 1.23961 4.76554 1.68704C4.76554 2.13447 4.96227 2.56358 5.31244 2.87996C5.66262 3.19634 6.13755 3.37408 6.63277 3.37408C7.12799 3.37408 7.60293 3.19634 7.9531 2.87996C8.30328 2.56358 8.5 2.13447 8.5 1.68704C8.5 1.23961 8.30328 0.810503 7.9531 0.494122C7.60293 0.177741 7.12799 0 6.63277 0ZM6.18464 5.36479C4.7033 5.47725 0.657644 8.39021 0.657644 8.39021C0.40868 8.55891 0.48337 8.54767 0.682541 8.86258C0.881712 9.16625 0.856815 9.18874 1.09333 9.04253C1.34229 8.89632 1.75308 8.66013 2.43773 8.27774C5.07675 6.74816 2.86097 10.2797 1.72819 16.2293C1.28005 19.176 4.21782 17.6577 4.97716 17.2078C5.72406 16.7692 7.72821 15.5208 7.92738 15.397C8.20124 15.2283 8.00207 15.0934 7.79045 14.8122C7.64108 14.621 7.4917 14.756 7.4917 14.756C6.68256 15.2396 5.20123 16.2518 5.00206 15.6107C4.76554 14.9697 6.28422 10.5721 7.11825 7.54669C7.25518 6.82689 7.62863 5.25232 6.18464 5.36479Z"
      fill="#1B1F2A"
    />
  </svg>
)

export const SettingsIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="43"
    height="36"
    viewBox="0 0 43 36"
    fill="none"
    className={className}
  >
    <path
      d="M18.9875 6.4755C19.6265 3.8415 23.3735 3.8415 24.0125 6.4755C24.1084 6.8712 24.2963 7.23867 24.5611 7.548C24.8258 7.85733 25.1598 8.09978 25.536 8.25562C25.9121 8.41146 26.3197 8.47628 26.7257 8.4448C27.1316 8.41333 27.5244 8.28645 27.872 8.0745C30.1865 6.6645 32.837 9.3135 31.427 11.6295C31.2153 11.977 31.0887 12.3695 31.0573 12.7752C31.0259 13.1808 31.0906 13.5882 31.2463 13.9641C31.4019 14.34 31.6441 14.6739 31.9531 14.9386C32.2621 15.2033 32.6292 15.3914 33.0245 15.4875C35.6585 16.1265 35.6585 19.8735 33.0245 20.5125C32.6288 20.6084 32.2613 20.7963 31.952 21.0611C31.6427 21.3258 31.4002 21.6598 31.2444 22.036C31.0885 22.4121 31.0237 22.8197 31.0552 23.2257C31.0867 23.6316 31.2135 24.0244 31.4255 24.372C32.8355 26.6865 30.1865 29.337 27.8705 27.927C27.523 27.7153 27.1305 27.5887 26.7248 27.5573C26.3192 27.5259 25.9118 27.5906 25.5359 27.7463C25.16 27.9019 24.8261 28.1441 24.5614 28.4531C24.2967 28.7621 24.1086 29.1292 24.0125 29.5245C23.3735 32.1585 19.6265 32.1585 18.9875 29.5245C18.8916 29.1288 18.7037 28.7613 18.4389 28.452C18.1742 28.1427 17.8402 27.9002 17.464 27.7444C17.0879 27.5885 16.6803 27.5237 16.2743 27.5552C15.8684 27.5867 15.4756 27.7135 15.128 27.9255C12.8135 29.3355 10.163 26.6865 11.573 24.3705C11.7847 24.023 11.9113 23.6305 11.9427 23.2248C11.9741 22.8192 11.9094 22.4118 11.7537 22.0359C11.5981 21.66 11.3559 21.3261 11.0469 21.0614C10.7379 20.7967 10.3708 20.6086 9.9755 20.5125C7.3415 19.8735 7.3415 16.1265 9.9755 15.4875C10.3712 15.3916 10.7387 15.2037 11.048 14.9389C11.3573 14.6742 11.5998 14.3402 11.7556 13.964C11.9115 13.5879 11.9763 13.1803 11.9448 12.7743C11.9133 12.3684 11.7865 11.9756 11.5745 11.628C10.1645 9.3135 12.8135 6.663 15.1295 8.073C16.6295 8.985 18.5735 8.178 18.9875 6.4755Z"
      stroke="#1B1F2A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 18C17 19.1935 17.4741 20.3381 18.318 21.182C19.1619 22.0259 20.3065 22.5 21.5 22.5C22.6935 22.5 23.8381 22.0259 24.682 21.182C25.5259 20.3381 26 19.1935 26 18C26 16.8065 25.5259 15.6619 24.682 14.818C23.8381 13.9741 22.6935 13.5 21.5 13.5C20.3065 13.5 19.1619 13.9741 18.318 14.818C17.4741 15.6619 17 16.8065 17 18Z"
      stroke="#1B1F2A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Email = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    fill="none"
  >
    <g clipPath="url(#clip0_4413_2109)">
      <path
        d="M3 7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H19C19.5304 5 20.0391 5.21071 20.4142 5.58579C20.7893 5.96086 21 6.46957 21 7V17C21 17.5304 20.7893 18.0391 20.4142 18.4142C20.0391 18.7893 19.5304 19 19 19H5C4.46957 19 3.96086 18.7893 3.58579 18.4142C3.21071 18.0391 3 17.5304 3 17V7Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 7L12 13L21 7" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_4413_2109">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const PasswordIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_342_657)">
      <path
        d="M5 13C5 12.4696 5.21071 11.9609 5.58579 11.5858C5.96086 11.2107 6.46957 11 7 11H17C17.5304 11 18.0391 11.2107 18.4142 11.5858C18.7893 11.9609 19 12.4696 19 13V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V13Z"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16Z"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_342_657">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const UserIconLight = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_673_2284)">
      <path
        d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_673_2284">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const EyeIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
)
export const EyeSlashIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
    />
  </svg>
)
export const UsersIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_673_2271)">
      <path
        d="M5 7C5 8.06087 5.42143 9.07828 6.17157 9.82843C6.92172 10.5786 7.93913 11 9 11C10.0609 11 11.0783 10.5786 11.8284 9.82843C12.5786 9.07828 13 8.06087 13 7C13 5.93913 12.5786 4.92172 11.8284 4.17157C11.0783 3.42143 10.0609 3 9 3C7.93913 3 6.92172 3.42143 6.17157 4.17157C5.42143 4.92172 5 5.93913 5 7Z"
        stroke="#546969"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 21V19C3 17.9391 3.42143 16.9217 4.17157 16.1716C4.92172 15.4214 5.93913 15 7 15H11C12.0609 15 13.0783 15.4214 13.8284 16.1716C14.5786 16.9217 15 17.9391 15 19V21"
        stroke="#546969"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3.13086C16.8604 3.35116 17.623 3.85156 18.1676 4.55317C18.7122 5.25478 19.0078 6.11769 19.0078 7.00586C19.0078 7.89403 18.7122 8.75694 18.1676 9.45855C17.623 10.1602 16.8604 10.6606 16 10.8809"
        stroke="#546969"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 21.0004V19.0004C20.9949 18.1175 20.6979 17.2612 20.1553 16.5648C19.6126 15.8684 18.8548 15.3711 18 15.1504"
        stroke="#546969"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_673_2271">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export const EyeClosedIcon = ({ className }: IconSvgProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_915_306)">
      <path
        d="M21 9C18.6 11.667 15.6 13 12 13C8.4 13 5.4 11.667 3 9"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14.9992L5.5 11.1992"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9998 14.9752L18.5078 11.1992"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17L9.5 13"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 17L14.5 13"
        stroke="#1B1F2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_915_306">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
